<template>
  <v-dialog v-model="showModal" scrollable max-width="600px" @click:outside="handleClose">
    <!-- @keydown="handleClose" -->
    <v-card color="white">
      <v-card-title class="text-subtitile-2 white--text py-1" :class="titleClass">
        <v-icon v-if="error" color="white" large>mdi-close-octagon</v-icon>
        <v-icon v-if="success" color="white" large>mdi-checkbox-marked-circle-outline</v-icon>
        <v-icon v-if="!error && !success" color="white" large>mdi-alert-box</v-icon>
        {{ modalTitle }}
      </v-card-title>

      <v-card-text class="py-3 grey--text text--darken-1" v-html="modalMessage"> </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          v-if="!success"
          color="primary"
          :loading="uploadLoading"
          :disabled="uploadLoading"
          @click="handleConfirm"
        >
          {{ confirmBtnLabel }}
        </v-btn>

        <v-btn v-if="success" class="white--text" color="green darken-1" @click="handleSuccess">
          {{ modalSuccessBtnLabel }}
        </v-btn>

        <v-btn
          v-if="showCancelBtn && !error && !success"
          color="red darken-1"
          text
          :disabled="uploadLoading"
          @click="handleClose"
        >
          {{ modalCancelBtnLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      error: 'ui/error',
      success: 'ui/success',
      uploadLoading: 'ui/uploadLoading',
      showModal: 'ui/showModal',
      showCancelBtn: 'ui/showCancelBtn',
      modalTitle: 'ui/modalTitle',
      modalMessage: 'ui/modalMessage',
      modalSuccessBtnLabel: 'ui/modalSuccessBtnLabel',
      modalConfirmBtnLabel: 'ui/modalConfirmBtnLabel',
      modalCancelBtnLabel: 'ui/modalCancelBtnLabel',
    }),
    titleClass() {
      return {
        'red accent-2': this.error,
        'light-blue lighten-1': !this.error && !this.success,
        success: this.success,
      };
    },
    confirmBtnLabel() {
      return this.error ? '閉じる' : this.modalConfirmBtnLabel;
    },
  },
  methods: {
    ...mapActions({
      toggleShowModal: 'ui/toggleShowModal',
      setSuccess: 'ui/setSuccess',
      setClickedConfirmFlag: 'ui/setClickedConfirmFlag',
      resetModalContents: 'ui/resetModalContents',
    }),
    handleClose() {
      this.resetModalContents();
      this.setClickedConfirmFlag(false);
      this.setSuccess(false);
      this.toggleShowModal();
    },
    handleConfirm() {
      if (!this.error) {
        this.setClickedConfirmFlag(true);
        this.$store.commit('ui/setShowCancelBtn', true);
      } else {
        this.handleClose();
      }
    },
    handleSuccess() {
      this.handleClose();
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
</style>
